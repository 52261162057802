import React from 'react';

const Calendar = ({ dayOfWeek, day, monthYear }) => {
    return (
        <div className="w-28 py-4 bg-white border border-gray-200">
            <div className="text-center">
                <div className="font-medium text-gray-600">{dayOfWeek}</div>
                <div className="text-4xl font-bold text-gray-800">{day}</div>
                <div className="text-sm text-gray-500">{monthYear}</div>
            </div>
        </div>
    );
};

export default Calendar;
