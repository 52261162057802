import React, { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { AuthorizationError } from '../api/lfApi';
import ToggleButton from './ToggleButton';
import LoadingButton from './LoadingButton';
import CheckoutButton from './CheckoutButton';
import BillingDetail from './BillingDetail';
import { getSubscriptionAction, setSubscriptionQuantity, getAdditionalChargeTotal, endTrial } from '../api/lfApiSubscription';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

async function resolveSubscriptionAction(action, quantity) {
    if (action.action === 'end_trial') {
        const _res = await endTrial();
    }
    else if (['increase_quantity', 'increase_quantity_trial'].includes(action.action)) {
        const _res = await setSubscriptionQuantity(quantity);
    }

    const nextAction = await getSubscriptionAction(true);
    if (nextAction) {
        return nextAction;
    }
    else {
        return {
            action: 'none',
        };
    }
}

const AddChildProfile = ({ childProfile, showConsent, quantity, onOk, onCancel }) => {
    const [childProfileEdited, setChildProfileEdited] = useState({
        ...(childProfile || {
            first_name: '',
            grade_level: '',
            username: '',
            password: '',
        }),
        parent_consent: showConsent ? 1 : 0,
        collect_usage_data: 1,
    });
    const [isParentConsent, setIsParentConsent] = useState(!showConsent);
    const [subscriptionAction, setSubscriptionAction] = useState({action: 'none'});
    const [isAdditionalChargeConsent, setIsAdditionalChargeConsent] = useState(false);
    const [errorType, setErrorType] = useState('');

    const handleProfileChange = (e) => {
        const { name, value } = e.target;
        setChildProfileEdited(prevState => ({ ...prevState, [name]: value }));
    };

    const isSaveEnabled = () => {
        if (!childProfileEdited.first_name) {
            return false;
        }
        if (!childProfileEdited.grade_level) {
            return false;
        }
        if (!childProfile && !childProfileEdited.username) {
            return false;
        }
        if (!childProfile && !childProfileEdited.password) {
            return false;
        }
        if (!isParentConsent) {
            return false;
        }
        if (['create_new', 'create_new_has_canceled'].includes(subscriptionAction.action)) {
            return false;
        }
        if (['increase_quantity', 'increase_quantity_trial', 'decrease_quantity', 'decrease_quantity_trial'].includes(subscriptionAction.action) && !isAdditionalChargeConsent) {
            return false;
        }
        if (subscriptionAction.action === 'end_trial') {
            return false;
        }
        return true;
    }

    const handleCancelTrial = async () => {
        const action = (subscriptionAction.action === 'end_trial') 
            ? await resolveSubscriptionAction(subscriptionAction, quantity + 1)
            : subscriptionAction;
        if (action) {
            setSubscriptionAction(action);
        }
    };

    const handleSave = async () => {
        setErrorType('');
        const action = (subscriptionAction.action === 'none') 
            ? subscriptionAction 
            : await resolveSubscriptionAction(subscriptionAction, quantity + 1);

        if (action.action === 'none') {
            try {
                await onOk(childProfileEdited);
            } catch (error) {
                if (error instanceof AuthorizationError) {
                    setErrorType('username_already_exists');
                    setChildProfileEdited(prevState => ({ ...prevState, username: '' }));
                } else {
                    console.error('Error in handleSave:', error.message);
                }
            }
        }
        else {
            setSubscriptionAction(action);
        }
    };

    useEffect(() => {
        setSubscriptionAction({action: 'none'});
        if (!childProfile) {
            getSubscriptionAction(true).then(action => {
                if (action) {
                    setSubscriptionAction(action);
                }
            });
        }
    }, [childProfile]);

    return <div className="text-left flex justify-center">
        <div>
            <h1 className="mb-4 text-lg">
                {childProfile ? "Edit child profile" : "Add a new child profile"}
            </h1>

            {
                ['create_new', 'create_new_has_canceled'].includes(subscriptionAction.action) && <div className="border border-blue-500 border-2 p-4 mb-8 md:w-3/4">
                    <div className="mb-4">
                        {
                            subscriptionAction.action === 'create_new' && <>
                                <div className="font-semibold">Unlock Full Access to Learnforest</div>
                                <div>
                                    Create personalized learning paths for your child. 
                                    Start your <span className="font-semibold">free 2-week trial</span> and see 
                                    how Learnforest can enhance your child's learning journey. 
                                    No commitment—cancel anytime.
                                </div>
                                <div class="mt-2">
                                    Pricing after the trial is $4.99 per month for each child account.
                                </div>
                            </>
                        }
                        {
                            subscriptionAction.action === 'create_new_has_canceled' && <>
                                Please subscribe to create a new child profile.
                            </>
                        }
                    </div>
                    <Elements stripe={stripePromise}>
                        <CheckoutButton 
                            text={subscriptionAction.action === 'create_new' ? 'Start free trial' : 'Subscribe'} 
                            price_type='monthly_standard' 
                            quantity={quantity + 1} />
                    </Elements>
                </div>
            }
            {
                subscriptionAction.action === 'end_trial' && <div className="border border-blue-500 border-2 p-4 mb-8 md:w-3/4">
                    <div className="font-semibold">You can only have 2 child profiles on the free trial</div>
                    <div>Please start your subscription to continue using Learnforest AI.</div>
                    <div className="mt-4">
                        <LoadingButton text="Start subscription" onClick={handleCancelTrial} />
                    </div>
                </div>
            }
            {
                errorType === 'username_already_exists' && <div className="border border-red-500 border-2 p-4 mb-8 md:w-3/4">
                    <div className="font-semibold">Username already exists</div>
                    <div>Please use a different username.</div>
                </div>
            }

            <div className="grid grid-cols-[auto,1fr] gap-2 items-center text-left mb-2">
                <div className="text-right">Display Name: </div>
                <div>
                    <input
                        type="text"
                        className="border border-gray-300 p-1"
                        name="first_name"
                        value={childProfileEdited.first_name}
                        onChange={handleProfileChange} />
                </div>
                <div className="text-right">Grade Level: </div>
                <div>
                    <input
                        type="text"
                        className="border border-gray-300 p-1"
                        name="grade_level"
                        value={childProfileEdited.grade_level}
                        onChange={handleProfileChange} />
                </div>
                { !childProfile && <div className="text-right">Username: </div> }
                { 
                    !childProfile && <div>
                        <input
                            type="text"
                            className="border border-gray-300 p-1"
                            name="username"
                            value={childProfileEdited.username}
                            onChange={handleProfileChange} />
                    </div> 
                }
                { !childProfile && <div className="text-right">Password: </div> }
                { 
                    !childProfile && <div>
                        <input
                            type="password"
                            className="border border-gray-300 p-1"
                            name="password"
                            value={childProfileEdited.password}
                            onChange={handleProfileChange} />
                    </div>
                }
            </div>

            {
                showConsent && <div className="py-4">
                    <div className="py-1 flex items-center">
                        <ToggleButton isToggled={isParentConsent} setIsToggled={setIsParentConsent} />
                        <span className={`${isParentConsent ? '' : 'text-gray-500'} ml-2 md:w-1/2`}>
                        I am the parent or legal guardian and I consent to the collection and use of my child's information
                        in accordance with the <a href="https://learnforest.ai/privacy.html" className="underline" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
                        </span>
                    </div>
                    <div className="py-1 flex items-center">
                        <ToggleButton isToggled={childProfileEdited.collect_usage_data && isParentConsent} setIsToggled={v => setChildProfileEdited(prevState => ({ ...prevState, collect_usage_data: v ? 1 : 0 }))} />
                        <span className={`${childProfileEdited.collect_usage_data && isParentConsent ? '' : 'text-gray-500'} ml-2 md:w-1/2`}>
                        I consent to the collection and use of app usage data to improve the learning experience.
                        </span>
                    </div>
                </div>
            }

            {
                ['increase_quantity', 'increase_quantity_trial'].includes(subscriptionAction.action) && <div className="mt-2 my-8">
                    <div>Adding a new child account will incur {subscriptionAction.action === 'increase_quantity' ? 'an additional charge' : 'a total charge'} of {getAdditionalChargeTotal(subscriptionAction)} in your next billing cycle.</div>
                    <BillingDetail 
                        subscriptionAction={subscriptionAction} 
                        isAdditionalChargeConsent={isAdditionalChargeConsent} 
                        setIsAdditionalChargeConsent={setIsAdditionalChargeConsent} 
                    />
                    <div className="pt-3 flex items-center">
                        <ToggleButton isToggled={isAdditionalChargeConsent} setIsToggled={setIsAdditionalChargeConsent} />
                        <span className={`${isAdditionalChargeConsent ? '' : 'text-gray-500'} ml-2 w-1/2`}>
                            I agree to {subscriptionAction.action === 'increase_quantity' ? 'the additional charge' : 'the total charge'} of {getAdditionalChargeTotal(subscriptionAction)} in the next billing cycle
                        </span>
                    </div>
                </div>
            }

            <div className="mt-4 flex gap-2">
                <LoadingButton text={ childProfile ? "Save": "Next" } onClick={handleSave} disabled={!isSaveEnabled()} />
                <button className="bg-blue-500 text-white py-2 px-4 rounded"
                    onClick={onCancel}>
                    Cancel
                </button>
            </div>
        </div>
    </div>;
};

export default AddChildProfile;
