// Function to chunk an array into smaller arrays of a given size
export function chunkArray(array, chunkSize) {
    return Array.from(
        { length: Math.ceil(array.length / chunkSize) },
        (v, i) => array.slice(i * chunkSize, i * chunkSize + chunkSize)
    );
}

export function extendArray(arr, targetLength, defaultValue) {
    if (targetLength > arr.length) {
        arr = arr.concat(Array(targetLength - arr.length).fill(defaultValue));
    }
    return arr;
}

export function deleteArrayElement(arr, index) {
    return arr.slice(0, index).concat(arr.slice(index + 1));
}
