import axios from 'axios';

const apiUrl = process.env.REACT_APP_LF_API_URL || 'http://localhost:5001';

axios.defaults.withCredentials = true;

export class AuthorizationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'AuthorizationError';
  }
}

export default axios.create({
  baseURL: apiUrl,
  timeout: 5000,
});
