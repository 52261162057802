import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import StudyLesson from './StudyLesson';
import StudyPlanTopic from './StudyPlanTopic';
import { stream_get, stream_post } from '../api/lfApiFetch';
import { parseLines, processText } from '../api/stream';
import { generateUniqueId } from '../api/uniqueId';
import { extendArray } from '../api/array';
import LoadingButton from './LoadingButton';

function parseLessons(lessons) {
    return parseLines(lessons).map((lesson) => {
        const separator = lesson.indexOf(':');
        const id = generateUniqueId('lesson_');
        if (separator === -1) {
            return {
                id: id,
                title: lesson,
                explanation: '',
            }
        }
        else {
            return {
                id: id,
                title: lesson.substring(0, separator),
                explanation: lesson.substring(separator + 1).trim(),
            }
        }
    });
};

function parseTopics(lessons, topics) {
    const topics_list = extendArray(
        parseLines(topics)
            .map((topic) => {
                const separator = topic.indexOf(':');
                const id = generateUniqueId('topic_');
                if (separator === -1) {
                    return null;
                }
                else {
                    return {
                        lesson_index: parseInt(topic.substring(0, separator)) - 1,
                        id: id,
                        title: topic.substring(separator + 1).trim(),
                    }
                }
            })
            .filter((topic) => topic !== null)
            .reduce((acc, topic) => {
                acc = extendArray(acc, topic.lesson_index + 1, []);
                acc[topic.lesson_index].push({
                    id: topic.id,
                    title: topic.title,
                });
                return acc;
            }, []), 
        lessons.length, 
        []);

    return lessons.map((lesson, index) => {
        return {
            ...lesson,
            topics: topics_list[index],
        };
    });
};

async function streamStudyPlans(plan, username, setLessons, nonse, llm_name = '') {
    const params = { 
        ...plan, 
        child_username: username,
        nonse,
        llm_name,
    };
    const res = await stream_get('lesson_plan/stream', params);
    const fullText = await processText(res, (text) => setLessons(parseLessons(text)));
    return parseLessons(fullText);
}

async function streamTopics(plan, username, lessons, setLessons, llm_name = '') {
    const params = {
        child_username: username,
        subject: plan.subject,
        goal: plan.goal,
        lessons: lessons,
    };
    const futures = [
        stream_post('lesson_plan/topics', { ...params, ...{ llm_name: llm_name } }),
    ];

    const onResponse = (lessons, setLessons) => {
        return async (res) => {
            await processText(res, (text) => setLessons(parseTopics(lessons, text)));
        };
    };
    const f1 = futures[0].then(onResponse(lessons, setLessons));
    await f1;
}

function getStudyPlan(plan, lessons) {
    return {
        id: plan.id,
        version: plan.version,
        subject: plan.subject,
        goal: plan.goal,
        previous_knowledge: plan.previous_knowledge,
        num_lessons: parseInt(plan.num_lessons),
        needs: plan.needs,
        emoji: plan.emoji,
        lessons: lessons,
    }
};

const EditStudyPlan = ({ lessonPlan, isArchived, onOk, onCancel }) => {
    let { username } = useParams();

    const [plan, setPlan] = useState(lessonPlan || {
        subject: '',
        goal: '',
        previous_knowledge: '',
        num_lessons: '5',
        needs: '',
        emoji: '',
    });
    const [isPreviewEnabled, setIsPreviewEnabled] = useState(true);
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [lessons1, setLessons1] = useState(lessonPlan ? lessonPlan.lessons : []);
    const [lessons2, setLessons2] = useState([]);

    const minNumLessons = 3;
    const maxNumLessons = 8;

    const maxNonse = 5;
    const [nonse, setNonse] = useState(0);

    const [draggingDraggableId, setDraggingDraggableId] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPlan(prevState => ({ ...prevState, [name]: value }));

        if (name === "emoji") {
            setIsSaveEnabled(true);
        } else {
            setIsPreviewEnabled(true);
        }
    };

    const incrementNonse = () => {
        const n = (nonse + 1) % maxNonse
        setNonse(n);
        return n;
    };

    const incrementNumLessons = async (incr) => {
        const currentNumLessons = parseInt(plan.num_lessons);
        const newNumLessons = currentNumLessons + incr;
        if (newNumLessons < minNumLessons || newNumLessons > maxNumLessons) {
            return;
        }

        const newPlan = { ...plan, num_lessons: newNumLessons.toString() };
        setPlan(newPlan);
        await onPreviewStudyPlans(newPlan, nonse);
    };

    const onPreviewStudyPlans = async (plan, nonse) => {
        if (!isPreviewEnabled) return;

        const llm_name = 'gemini';
        const lessons = await streamStudyPlans(plan, username, setLessons2, nonse, llm_name);

        let topicsFuture = [];
        if (!lessons1[0].topics || lessons1[0].topics.length === 0)
            topicsFuture.push(streamTopics(plan, username, lessons1, setLessons1));
        topicsFuture.push(streamTopics(plan, username, lessons, setLessons2, llm_name));

        await Promise.all(topicsFuture);
    };

    const onDragUpdate = (update) => {
        setDraggingDraggableId(!update.destination ? update.draggableId : '');
    };
    
    const onDragEnd = (result) => {
        setDraggingDraggableId('');

        if (result.type === "lessons")
            onDragLesson(result);
        else
            onDragTopic(result);

        setIsSaveEnabled(true);
    };

    const onDragLesson = (result) => {
        const { source, destination } = result;

        if (!destination || source.droppableId === destination.droppableId) {
            const { lessons, setLessons } = source.droppableId === 'lesson1' 
                ? { lessons: lessons1, setLessons: setLessons1 }
                : { lessons: lessons2, setLessons: setLessons2 };

            const [removed] = lessons.splice(source.index, 1);
            if (destination)
                lessons.splice(destination.index, 0, removed);
            setLessons([...lessons]);
        } else {
            const { sourceLessons, setSourceLessons } = source.droppableId === 'lesson1' 
                ? { sourceLessons: lessons1, setSourceLessons: setLessons1 } 
                : { sourceLessons: lessons2, setSourceLessons: setLessons2 };
            const { destinationLessons, setDestinationLessons } = destination.droppableId === 'lesson1' 
                ? { destinationLessons: lessons1, setDestinationLessons: setLessons1 }
                : { destinationLessons: lessons2, setDestinationLessons: setLessons2 };

            const [removed] = sourceLessons.splice(source.index, 1);
            destinationLessons.splice(destination.index, 0, removed);

            setSourceLessons([...sourceLessons]);
            setDestinationLessons([...destinationLessons]);
        }
    }

    const onDragTopic = (result) => {
        const { source, destination } = result;
    
        if (!destination) {
            // DroppableIds are in the format '<topics>:<lessonIndex>'
            const sourceDroppableId = source.droppableId.split(':')[0];
            const sourceLessonIndex = parseInt(source.droppableId.split(':')[1], 10);

            // Index is the topic index within the lesson
            const sourceTopicIndex = source.index;

            const { lessons, setLessons } = sourceDroppableId === 'lesson1'
                ? { lessons: lessons1, setLessons: setLessons1 }
                : { lessons: lessons2, setLessons: setLessons2 };

            // Remove topic from the source lesson
            const newSourceLessons = [...lessons];
            const sourceLesson = newSourceLessons[sourceLessonIndex];
            sourceLesson.topics.splice(sourceTopicIndex, 1);

            setLessons([...newSourceLessons]);
        } else {
            const getLessonsList = (droppableId) => (droppableId === 'lesson1' ? lessons1 : lessons2);
            const setLessonsList = (droppableId, newLessons) => (droppableId === 'lesson1' ? setLessons1(newLessons) : setLessons2(newLessons));
        
            // DroppableIds are in the format '<topics>:<lessonIndex>'
            const sourceDroppableId = source.droppableId.split(':')[0];
            const destinationDroppableId = destination.droppableId.split(':')[0];
            const sourceLessonIndex = parseInt(source.droppableId.split(':')[1], 10);
            const destinationLessonIndex = parseInt(destination.droppableId.split(':')[1], 10);

            const sourceLessons = getLessonsList(sourceDroppableId);
            const destinationLessons = getLessonsList(destinationDroppableId);
        
            // Index is the topic index within the lesson
            const sourceTopicIndex = source.index;
            const destinationTopicIndex = destination.index;

            // Remove topic from the source lesson
            const newSourceLessons = [...sourceLessons];
            const sourceLesson = newSourceLessons[sourceLessonIndex];
            const [movedTopic] = sourceLesson.topics.splice(sourceTopicIndex, 1);
        
            if (sourceDroppableId === destinationDroppableId && sourceLessonIndex === destinationLessonIndex) {
                // Moving within the same list
                sourceLesson.topics.splice(destinationTopicIndex, 0, movedTopic);
                setLessonsList(sourceDroppableId, newSourceLessons);
            } else {
                // Moving between lists
                const newDestinationLessons = [...destinationLessons];
                const destinationLesson = newDestinationLessons[destinationLessonIndex];
                destinationLesson.topics.splice(destinationTopicIndex, 0, movedTopic);
            
                setLessonsList(sourceDroppableId, newSourceLessons);
                setLessonsList(destinationDroppableId, newDestinationLessons);
            }
        }
    };

    return <div className='text-left mx-auto max-w-screen-lg'>
        <h1 className="text-xl">
            {lessonPlan ? "Edit study plan" : "Add study plan"}
        </h1>

        {
            !lessonPlan && <div>
                <div>
                    A study plan is a personalized learning plan designed to guide your child through their learning goals.
                </div>
                <div>
                    Create a new study plan by entering the following information:
                </div>
            </div>
        }

        <table className="table-auto w-full mt-2">
            <colgroup>
                <col />
                <col className="w-full" />
            </colgroup>
            <tbody>
                <tr>
                    <td className="text-right p-1">Subject</td>
                    <td className="text-left p-1">
                        <input
                            type="text"
                            className="w-full border border-gray-300 p-1"
                            name="subject"
                            placeholder="eg, Math, Science, English, etc."
                            value={plan.subject}
                            onChange={handleChange} />
                    </td>
                </tr>
                <tr>
                    <td className="text-right align-top p-1">Learning goals</td>
                    <td className="p-1">
                        <textarea
                            className="border border-gray-300 w-full p-1"
                            name="goal"
                            placeholder="eg, Master 6th grade math concepts, develop reading comprehension skills and build vocabulary, etc."
                            value={plan.goal}
                            onChange={handleChange} />
                    </td>
                </tr>
                <tr>
                    <td className="text-right align-top p-1 whitespace-nowrap">Previous knowledge</td>
                    <td className="p-1">
                        <textarea
                            className="border border-gray-300 w-full p-1"
                            name="previous_knowledge"
                            placeholder="eg, Fully understands 5th grade math, can read at grade level but struggles with comprehension of more complex texts, etc."
                            value={plan.previous_knowledge}
                            onChange={handleChange} />
                    </td>
                </tr>
                <tr>
                    <td className="text-right align-top p-1">Needs (optional)</td>
                    <td className="p-1">
                        <textarea
                            className="border border-gray-300 w-full p-1"
                            name="needs"
                            placeholder="eg, Requires extra help with grammar and sentence structure, etc"
                            value={plan.needs}
                            onChange={handleChange} />
                    </td>
                </tr>
                <tr>
                    <td className="text-right align-top p-1">Emoji</td>
                    <td className="p-1">
                        <input type="text"
                            className="border border-gray-300 w-full p-1"
                            name="emoji"
                            maxLength={2}
                            value={plan.emoji}
                            onChange={handleChange} />
                    </td>
                </tr>
            </tbody>
        </table>

        <div className="flex flex-col sm:flex-row justify-center items-center gap-2 mt-2">
            <div className="flex gap-2">
                <LoadingButton text="Suggest study plan" onClick={() => onPreviewStudyPlans(plan, incrementNonse())} />
                <LoadingButton text="Make shorter" onClick={() => incrementNumLessons(-1)} />
                <LoadingButton text="Make longer" onClick={() => incrementNumLessons(1)} />
            </div>

            <div className="hidden sm:flex items-center h-full mx-1">
                <div>&nbsp;</div>
                <div className="border-l border-gray-300 h-full">&nbsp;</div>
            </div>

            <div className="flex gap-2">
                {
                    lessons2.length === 0 && 
                    <button 
                        className={`${isSaveEnabled ? "bg-blue-500" : "bg-gray-400"} text-white py-2 px-4 rounded`}
                        disabled={!isSaveEnabled}
                        onClick={() => onOk(getStudyPlan(plan, lessons1))}>
                        Save
                    </button>
                }
                <button className="bg-blue-500 text-white py-2 px-4 rounded"
                    onClick={onCancel}>
                    Cancel
                </button>
            </div>
        </div>

        {
            lessons1.length > 0 && <div className="mt-6">
                <h1 className="text-lg">
                    {lessons2.length > 0 ? "Study plans" : "Current study plan"}
                </h1>

                <div className="flex flex-col md:flex-row gap-4 text-left mt-2">
                    <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
                        <div className="flex-1">
                            {
                                lessons2.length > 0 && <>
                                    <hr className="md:hidden mb-2" />
                                    <div className="mb-4">Current study plan:</div>
                                </>
                            }
                            <Droppable droppableId="lesson1" type="lessons">
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {
                                        lessons1.map((lesson, lessonIndex) => (
                                            <Draggable key={`lesson1:${lessonIndex}`} draggableId={`lesson1:${lessonIndex}`} index={lessonIndex}>
                                                {(provided) => (
                                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                        className={`${`lesson1:${lessonIndex}` === draggingDraggableId ? 'opacity-50' : 'opacity-100'}`}>
                                                        <StudyLesson lesson={lesson} index={lessonIndex} isLastLesson={lessonIndex === lessons1.length - 1}>
                                                            <Droppable key={`lesson1:${lessonIndex}`} droppableId={`lesson1:${lessonIndex}`} type="topics">
                                                            {(provided) => (
                                                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                                                {
                                                                    lesson.topics && lesson.topics.map((topic, topicIndex) => (
                                                                        <div key={`lesson1:${topic.id}`}>
                                                                            <Draggable draggableId={`lesson1:${lessonIndex}:${topicIndex}`} 
                                                                                    key={`${lessonIndex}:${topicIndex}`} 
                                                                                    index={topicIndex}>
                                                                                {(provided) => (
                                                                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                                                        className={`${`lesson1:${lessonIndex}:${topicIndex}` === draggingDraggableId ? 'opacity-50' : 'opacity-100'}`}>
                                                                                        <StudyPlanTopic topic={topic} index={topicIndex} />
                                                                                    </div>
                                                                                )}
                                                                            </Draggable>
                                                                        </div>
                                                                    ))
                                                                }
                                                                {provided.placeholder}
                                                                </div>
                                                            )}
                                                            </Droppable>
                                                        </StudyLesson>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))
                                    }
                                    {provided.placeholder}
                                </div>
                            )}
                            </Droppable>
                            {
                                lessons2.length > 0 && <div className="md:hidden">
                                    <button className="bg-blue-500 text-white py-2 px-4 rounded"
                                        onClick={() => onOk(getStudyPlan(plan, lessons1))}>
                                        Keep current study plan
                                    </button>
                                </div>
                            }
                        </div>

                        {
                            lessons2.length > 0 && <div className="flex-1">
                                <hr className="md:hidden mb-2" />
                                <div className="mb-4">Suggested study plan:</div>
                                <Droppable droppableId="lesson2" type="lessons">
                                {(provided) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {
                                            lessons2.map((lesson, lessonIndex) => (
                                                <Draggable key={`lesson2:${lessonIndex}`} draggableId={`lesson2:${lessonIndex}`} index={lessonIndex}>
                                                    {(provided) => (
                                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                            className={`${`lesson2:${lessonIndex}` === draggingDraggableId ? 'opacity-50' : 'opacity-100'}`}>
                                                            <StudyLesson lesson={lesson} index={lessonIndex} isLastLesson={lessonIndex === lessons2.length - 1}>
                                                                <Droppable key={`lesson2:${lessonIndex}`} droppableId={`lesson2:${lessonIndex}`} type="topics">
                                                                {(provided) => (
                                                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                                                    {
                                                                        lesson.topics && lesson.topics.map((topic, topicIndex) => (
                                                                            <div key={`lesson2:${topic.id}`}>
                                                                                <Draggable draggableId={`lesson2:${lessonIndex}:${topicIndex}`} 
                                                                                        key={`${lessonIndex}:${topicIndex}`} 
                                                                                        index={topicIndex}>
                                                                                    {(provided) => (
                                                                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                                                            className={`${`lesson2:${lessonIndex}:${topicIndex}` === draggingDraggableId ? 'opacity-50' : 'opacity-100'}`}>
                                                                                            <StudyPlanTopic topic={topic} index={topicIndex} />
                                                                                        </div>
                                                                                    )}
                                                                                </Draggable>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                    {provided.placeholder}
                                                                    </div>
                                                                )}
                                                                </Droppable>
                                                            </StudyLesson>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))
                                        }
                                        {provided.placeholder}
                                    </div>
                                )}
                                </Droppable>
                                <div className="md:hidden">
                                    <button className="bg-blue-500 text-white py-2 px-4 rounded"
                                        onClick={() => onOk(getStudyPlan(plan, lessons2))}>
                                        Accept suggested study plan
                                    </button>
                                </div>
                            </div>
                        }
                    </DragDropContext>
                </div>

                {
                    lessons2.length > 0 && <div className="hidden md:flex justify-center gap-2 mt-4">
                        <button className="bg-blue-500 text-white py-2 px-4 rounded ml-12"
                            onClick={() => onOk(getStudyPlan(plan, lessons1))}>
                            Keep current study plan
                        </button>
                        <button className="bg-blue-500 text-white py-2 px-4 rounded"
                            onClick={() => onOk(getStudyPlan(plan, lessons2))}>
                            Accept suggested study plan
                        </button>
                    </div>
                }
            </div>
        }

    </div>
};

export default EditStudyPlan;
